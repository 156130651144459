import React, { useState, useEffect, useContext, useMemo } from "react";
import { useSearchParams, useLocation, useNavigate , useParams} from "react-router-dom";

import { performApiRequest } from "src/utils/apiRequest";
import { UserStoryContext } from "src/context/UserStoryState";

// Organization ID to folder name mapper
const orgMapper = {
  1: "hotel",
  "64f9f31ab8f2b3a65cbfc48d": "64f9f31ab8f2b3a65cbfc48d",
  "6731ec4e94e023f1ce6016b5":"6731ec4e94e023f1ce6016b5",
  "665467d84b7c867c744381a0":"665467d84b7c867c744381a0",
  "64f9f31ab8f2b3acae3x4sfe":"64f9f31ab8f2b3acae3x4sfe"
  // Add more mappings as needed
};

const DynamicImport = () => {
  const { contextData, AddContextData } = useContext(UserStoryContext);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [UserStoryComponent, setUserStoryComponent] = useState(null);
  const [SidebarComponent, setSidebarComponent] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // State to track API call status
  const { orgURL } = useParams();

  const [tenantIdURL, setTenantIdURL] = useState("");

  useEffect(() => {
    setTenantIdURL(orgURL || ""); // Use the org name to set tenant ID dynamically
  }, [orgURL]);
  // Memoize userData to avoid recalculating on every render
  const userData = useMemo(() => JSON.parse(localStorage.getItem("userData")) ?? {}, []);
  const { access_token: token, tenant_id: tenantId, scope: role, load } = userData;

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!token) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`);
    }
  }, [token, navigate, location.pathname, location.search]);

  // Memoize folderPath based on orgMapper and userData
  const folderPath = useMemo(() => {
    const org = tenantId ? orgMapper[tenantId] || "defaultOrg" : tenantIdURL;
    return `UserStory/${org}/${role}`;
  }, [tenantId, role]);

  // Load components dynamically based on conditions
  useEffect(() => {
    const loadComponents = async () => {
      try {
        const templateName = searchParams.get("templateName");
        const org = folderPath.split("/")[1];

        // Fetch trainee data if not already loaded
        if (
          (org === "64f9f31ab8f2b3a65cbfc48d" || org === "64f9f31ab8f2b3acae3x4sfe") &&
          !dataLoaded && 
          (load !== false || !contextData.some(data => data.hasOwnProperty("token")))
        ) {
          await traineeAdmin(userData, AddContextData);
          setDataLoaded(true); // Mark data as loaded
        }

        // Dynamic import for Sidebar component
        const { default: Sidebar } = await import(`src/pages/${folderPath}/Sidebar`);
        setSidebarComponent(() => Sidebar);

        // Dynamic import for UserStory component based on templateName
        const { default: Component } = await import(
          `src/pages/${folderPath}/${templateName ?? "Dashboard"}`
        );
        console.log('Component: ', Component);
        
        setUserStoryComponent(() => Component);
      } catch (error) {
        console.error("Error loading components", error);
      }
    };

    if (token) {
      loadComponents();
    }
  }, [folderPath, searchParams, token, contextData, dataLoaded, userData, AddContextData]);

  if (!UserStoryComponent || !SidebarComponent) {
    return <div>Loading...</div>;
  }else
{
  console.log("UserStoryComponent",UserStoryComponent)
}
  return (
    <div className="flex h-screen bg-gray-100">
      <SidebarComponent isExpanded={isSidebarExpanded} setIsExpanded={setIsSidebarExpanded} />
      {/* {contextData.some(data => data.hasOwnProperty("token")) && <UserStoryComponent />} */}
      { <UserStoryComponent />}
    </div>
  );
};

export default DynamicImport;

// Helper function for trainee admin data fetching
async function traineeAdmin(userData, AddContextData) {
  var userPayload
   var response ;
 if(userData.scope==="trainee"){
  userPayload = {
    target: "trainee",
    action: "search",
    filters: {
      logicalOperator: "AND",
      rules: [
        {
          field: "email",
          dataType: "tag",
          comparisonOperator: "equals",
          value: userData?.user_id,
        },
      ],
    },
    columnsToRetrieve: [
      { field: "recordId", dataType: "tag" },
      { field: "name", dataType: "tag" },
      { field: "dateOfBirth", dataType: "tag" },
      { field: "traineeGender", dataType: "tag" },
      { field: "contactNumberPhoneNumber", dataType: "tag" },
      { field: "email", dataType: "tag" },
      { field: "qualificationAttained", dataType: "tag" },
      { field: "englishProficiency", dataType: "tag" },
      { field: "traineeStatus", dataType: "tag" },
      { field: "traineeId", dataType: "tag" },
      { field: "idType2", dataType: "tag" },
      { field: "idType1", dataType: "tag" },
      { field: "lmsEmail", dataType: "tag" },
      { field: "race", dataType: "tag" },
      { field: "contactNumberContactType", dataType: "tag" },
      { field: "nationality", dataType: "tag" },
    ],
    resultDisplayOrder: "row",
    maxRowsPerPage: 1,
    pageToRetrieve: 1,
  };
  response = await performApiRequest(1, "bexInsights", "/bexinsights/search", "post", userPayload);
 }else if(userData.scope==="agency"){
  userPayload = {
    target: "agency",
    action: "search",
    filters: {
      logicalOperator: "AND",
      rules: [
        {
          field: "user",
          dataType: "tag",
          comparisonOperator: "equals",
          value: userData?.user_id,
        },
      ],
    },
    columnsToRetrieve: [
      // { field: "recordId", dataType: "tag" },
      // { field: "name", dataType: "tag" },
      // { field: "dateOfBirth", dataType: "tag" },
      // { field: "traineeGender", dataType: "tag" },
      // { field: "contactNumberPhoneNumber", dataType: "tag" },
      // { field: "email", dataType: "tag" },
      // { field: "qualificationAttained", dataType: "tag" },
      // { field: "englishProficiency", dataType: "tag" },
      // { field: "traineeStatus", dataType: "tag" },
      // { field: "traineeId", dataType: "tag" },
      // { field: "idType2", dataType: "tag" },
      // { field: "idType1", dataType: "tag" },
      // { field: "lmsEmail", dataType: "tag" },
      // { field: "race", dataType: "tag" },
      // { field: "contactNumberContactType", dataType: "tag" },
      // { field: "nationality", dataType: "tag" },
    ],
    resultDisplayOrder: "row",
    maxRowsPerPage: 1,
    pageToRetrieve: 1,
  };
  response = await performApiRequest(1, "bexInsights", "/bexinsights/search", "post", userPayload);
 }


  try {
    // const response = await performApiRequest(1, "bexInsights", "/bexinsights/v2/search", "post", userPayload);
    const data = response?.data?.tableRow?.[0];

    if (data) {
      console.log("bati",data.agency)
      const mergedData = {
        ...data,
        token: userData?.access_token, // From userData
        tenant_id: userData?.tenant_id, // From userData
        email: userData?.user_id || data.email, // Prefer userData email; fallback to data email
        ccAgency: data.agency ? data.agency : undefined, // Set to '' if agency exists, undefined otherwise
      };
      
      

      AddContextData(mergedData);

      const updatedUserData = { ...userData, load: false };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    } else {
      console.warn("No data found for the trainee");
    }
  } catch (error) {
    console.error("Error during API request:", error);
  }
}
